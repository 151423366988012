import { Button } from "reactstrap";
import useTheme from "../../helpers/useTheme";

const AppButton = ({
  text = "Submit",
  size = "sm",
  type = "button",
  children,
  ...rest
}) => {
  const { color: c = {}, bgColor, tertiaryColor } = useTheme();

  return (
    <>
      <Button
        type={type}
        size={size}
        color={!c.color ? "primary" : null}
        style={{ ...c, ...bgColor, color: tertiaryColor, fontSize: "14px" }}
        {...rest}
      >
        {children || text}
      </Button>
    </>
  );
};

export default AppButton;
