import React, { Suspense, useState, useEffect, lazy } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import VerticalLayout from "./Layouts/VerticalLayout/";
import NonAuthLayout from "./Layouts/NonAuthLayout";
import AppRoute from "../routes/routes";
import { authProtectedRoutes, publicRoutes } from "../routes/";
import { requestCurrentUser } from "../actions/user";
import "../assets/scss/theme.scss";
import PreLoader from "./common/PreLoader";
import useTheme from "../helpers/useTheme";
import { requestTheme } from "../actions/settings";
import { requestPricings } from "../actions/pricings";
import { setGlobalAlert } from "../actions/common";
import Snackbar from "./common/Snackbar";
import AllInitialApiCallsComponent from "./common/AllInitialApiCallsComponent";
import { CircularProgress } from "@mui/material";

const ShopifyUser = lazy(() => import("../pages/redirects/ShopifyUser"));
const Pages404 = lazy(() => import("../pages/static/404"));

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { logo = {} } = useTheme();

  const globalAlert = useSelector((state) => state?.common?.alert);
  const circularProgress = useSelector(
    (state) => state?.common?.circularProgress
  );

  // useEffect(() => {
  //   if (logo?.url) {
  //     const favicon = document.getElementById("favicon");
  //     favicon.href = logo?.favicon || logo?.url;
  //   }
  // }, [logo]);

  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      dispatch(requestCurrentUser()),
      dispatch(
        requestTheme({ consumer_key: "Kykpyje2bhg3265E3KDB8ttSt4rZC5IT" })
      ),
      dispatch(requestPricings()),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <PreLoader />;
  }

  return (
    <React.Fragment>
      <AllInitialApiCallsComponent />

      <Router>
        <Suspense fallback={<PreLoader />}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact={route.exact}
              />
            ))}

            <Route path="/shopify-user" exact component={ShopifyUser} />
            <Route path="/app-user" exact component={ShopifyUser} />
            <Route component={Pages404} />
          </Switch>
        </Suspense>
      </Router>

      {circularProgress && (
        <CircularProgress className="in-the-center-and-on-top-of-all" />
      )}

      {!isEmpty(globalAlert) && (
        <Snackbar
          alert={globalAlert}
          onClose={() => dispatch(setGlobalAlert({}))}
          open={!isEmpty(globalAlert)}
        />
      )}
    </React.Fragment>
  );
};

export default App;
