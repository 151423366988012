import {
  REQUEST_PLACEMENTS_SUCCESS,
  REQUEST_PLACEMENTS_FAILURE,
  REQUEST_TOURS_SUCCESS,
  UPDATE_TOURS_SUCCESS,
  LOADING_TOURS,
  REQUEST_PRINT_MODES_SUCCESS,
  REQUEST_THEMES_SUCCESS,
  GET_PRICING_TEMPLATES_SUCCESS,
  GET_PRICING_TEMPLATES_FAILURE,
  ADD_PRICING_TEMPLATES_SUCCESS,
  ADD_PRICING_TEMPLATES_FAILURE,
  GET_ORDER_STATUS_COLOR_TAGS_SUCCESS,
  ADD_ORDER_STATUS_COLOR_TAGS_SUCCESS,
} from "../../constants/actionTypes";
import { domainName } from "../../utils";
// import defaultLogo from "../../assets/images/Logo.png";

const ORDER_STATUS_COLORS = [
  { hours: 0, color: "#008000", key: "1", label: "less than a day old" },
  { hours: 24, color: "#FFA500", key: "2", label: "a day old" },
  { hours: 48, color: "#FF0000", key: "3", label: "more than a day old" },
];

const INITIAL_STATE = {
  placements: {},
  discounts: {},
  activePlacements: {},
  toursCompleted: {},
  tourLoading: false,
  printModes: [],
  themeData: {},
  pricingTemplate: {},
  orderStatusColor: ORDER_STATUS_COLORS,
};

export default function settings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_PLACEMENTS_SUCCESS:
      const placements = {
        ...(action?.payload?.data?.placements || {}),
      };
      const discounts = action?.payload?.data?.discounts || {};
      const activePlacements = action?.payload?.data?.activePlacements || {};

      return {
        ...state,
        placements,
        discounts,
        activePlacements,
      };

    case REQUEST_PLACEMENTS_FAILURE:
      return { ...state, error: "Record not found" };

    case REQUEST_TOURS_SUCCESS:
      return {
        ...state,
        toursCompleted: action?.payload?.data?.toursCompleted || {},
      };

    case UPDATE_TOURS_SUCCESS:
      const upadtedToursCompleted = {
        ...(state?.toursCompleted || {}),
        ...(action?.payload?.data?.toursCompleted || {}),
      };

      return {
        ...state,
        toursCompleted: upadtedToursCompleted,
      };

    case LOADING_TOURS:
      return {
        ...state,
        tourLoading: action.payload,
      };

    case REQUEST_PRINT_MODES_SUCCESS:
      const pm = action?.payload?.print_modes || [];
      return {
        ...state,
        printModes: ["", ...pm],
      };

    case REQUEST_THEMES_SUCCESS:
      const theme = action?.payload?.data || {};

      return {
        ...state,
        themeData: {
          bgColor: {
            background: theme?.primaryGradient || theme?.primaryColor,
          },
          color: { color: theme?.tertiaryColor || theme?.secondaryColor },
          // logo: theme?.logo || { url: defaultLogo },
          logo: theme?.logo,
          enterprise: theme?.enterprise || {
            name: domainName,
          },
          ...theme,
        },
      };

    case GET_PRICING_TEMPLATES_SUCCESS:
      return {
        ...state,
        pricingsTemplates: action?.payload?.data?.pricingsTemplates || {},
      };

    case GET_PRICING_TEMPLATES_FAILURE:
      return { ...state, error: "Record not found" };

    case ADD_PRICING_TEMPLATES_SUCCESS:
      const updatedPricingTable = [
        ...state.pricingsTemplates,
        action?.payload?.data,
      ];
      return {
        ...state,
        pricingsTemplates: updatedPricingTable,
      };

    case ADD_PRICING_TEMPLATES_FAILURE:
      return { ...state, error: "Failed to Add Template" };

    case GET_ORDER_STATUS_COLOR_TAGS_SUCCESS:
      const orderStatusColor =
        action?.payload?.data?.statusColors || ORDER_STATUS_COLORS;
      return { ...state, orderStatusColor };

    case ADD_ORDER_STATUS_COLOR_TAGS_SUCCESS:
      const updatedStatusColors =
        action?.payload?.data?.statusColors || ORDER_STATUS_COLORS;
      return { ...state, orderStatusColor: updatedStatusColors };

    default:
      return state;
  }
}
