import { useState, useEffect, lazy, Suspense } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";

import {
  changeSidebarType,
  changeSidebarTheme,
  changeLayoutWidth,
  changeTopbarTheme,
} from "../../../actions/layout";
import ErrorBoundary from "../../ErrorBoundary";
import { capitalizedPathName } from "../../../helpers/string";
import Footer from "./Footer";
import useTheme from "../../../helpers/useTheme";
import PreLoader from "../../common/PreLoader";
import {
  isHoaxMerchDomain,
  isLarevivalDomain,
  currentDomain,
  isDevDomain,
  isMerchfillerDomain,
  isMyShirtPodDomain,
  isJmDtgPrintingDomain,
  isDtgShirtsDomain,
  isPrintPoetsDomain,
  isLuxtownDomain,
  isPrintifyDomain,
  isRakilineDomain,
  isTeeprintcentreDomain,
  isTheSublimationStationDomian,
} from "../../../utils";
import "./styles.scss";
import LoadHelpCrunchChat from "./LoadHelpCrunchChat";
import RightDrawer from "../../RightDrawer";
import { RIGHT_DRAWER_WIDTH } from "../../../constants/dashboard";
import { logoutUser } from "../../../actions/authentication";

const HoaxMerchHeader = lazy(() => import("./HoaxMerch/Header"));
const HoaxMerchSidebar = lazy(() => import("./HoaxMerch/Sidebar"));
const LarevivalHeader = lazy(() => import("./Larevival/Header"));
const LarevivalSidebar = lazy(() => import("./Larevival/Sidebar"));
const RiverrHeader = lazy(() => import("./Riverr/Header"));
const RiverrSidebar = lazy(() => import("./Riverr/Sidebar"));

const VerticalLayout = ({
  children,
  leftSideBarType,
  leftSideBarTheme,
  layoutWidth,
  topbarTheme,
  currentUser,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { enterprise } = useTheme();

  const [isMobile, setIsMobile] = useState(true);

  const currentActiveStep = useSelector(
    (state) => state?.dashboard?.currentActiveStep
  );

  const currentEnterprise = useSelector(
    (state) => state.currentUser.enterprise
  );
  const paymentMethods = useSelector(
    (state) => state.credentials.paymentMethods
  );
  const paymentMethodsLoading = useSelector(
    (state) => state?.credentials?.loading
  );
  const planId = useSelector((state) => state?.pricings?.planId);

  // redirect admins to profile page if no credit card details added
  useEffect(() => {
    if (
      !isEmpty(currentUser) &&
      currentUser.isEnterpriseAdmin &&
      !currentUser.isProUser &&
      !paymentMethodsLoading &&
      !isDevDomain &&
      !isPrintifyDomain &&
      !isRakilineDomain &&
      !isTeeprintcentreDomain &&
      !isTheSublimationStationDomian
      // (isMerchfillerDomain ||
      //   isMyShirtPodDomain ||
      //   isJmDtgPrintingDomain ||
      //   isDtgShirtsDomain ||
      //   isPrintPoetsDomain ||
      //   isLuxtownDomain)
    ) {
      if (isEmpty(paymentMethods) && location.pathname !== "/profile") {
        history.push("/profile");
      } else if (
        !isEmpty(paymentMethods) &&
        !planId &&
        location.pathname !== "/pricing"
      ) {
        history.push("/pricing");
      }
    }
  }, [currentUser, paymentMethodsLoading, location]);

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    let currentpage = capitalizedPathName(location.pathname);

    document.title = currentpage + ` | ${enterprise?.name}`;
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }

    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }

    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [
    dispatch,
    layoutWidth,
    leftSideBarTheme,
    leftSideBarType,
    topbarTheme,
    location.pathname,
  ]);

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  const DynamicComponents = (function () {
    if (isHoaxMerchDomain) {
      return {
        HeaderComponent: HoaxMerchHeader,
        SidebarComponent: HoaxMerchSidebar,
      };
    }

    if (isLarevivalDomain) {
      return {
        HeaderComponent: LarevivalHeader,
        SidebarComponent: LarevivalSidebar,
      };
    }

    return {
      HeaderComponent: RiverrHeader,
      SidebarComponent: RiverrSidebar,
    };
  })();

  let isUserFromCurrentDomain = true;

  if (!isEmpty(currentEnterprise?.hostnames) && currentDomain) {
    isUserFromCurrentDomain =
      currentEnterprise?.hostnames?.includes(currentDomain);
  }

  if (!isUserFromCurrentDomain && !isDevDomain) {
    return (
      <div className="p-5">
        <p className="h2">
          Not Authorized to access this domain. Kindly use one of the following
          domains:
        </p>
        <div>
          {currentEnterprise?.hostnames?.map((hn) => (
            <div key={hn} className="h3">
              <a href={`//${hn}`} target="_blank">
                {hn}
              </a>
            </div>
          ))}
        </div>

        <div className="mt-3">
          Click here to{" "}
          <b
            role="button"
            onClick={() => dispatch(logoutUser(history))}
            className="text-primary"
          >
            Logout
          </b>
        </div>
      </div>
    );
  }

  const showFooter = !location.pathname.includes("nester");

  return (
    <Suspense fallback={<PreLoader />}>
      <LoadHelpCrunchChat {...{ currentUser }} />

      <div id="layout-wrapper">
        <DynamicComponents.HeaderComponent
          toggleMenu={toggleMenuCallback}
          currentUser={currentUser}
          marginRight={currentActiveStep ? RIGHT_DRAWER_WIDTH : "initial"}
        />
        <DynamicComponents.SidebarComponent
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div
          className="main-content"
          style={{
            minHeight: "calc(100vh - 60px)",
            marginRight: currentActiveStep ? RIGHT_DRAWER_WIDTH : "initial",
          }}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
          {currentActiveStep && (
            <RightDrawer open={Boolean(currentActiveStep)} />
          )}
        </div>
        {showFooter && <Footer />}
      </div>
    </Suspense>
  );
};

const mapStatetoProps = (state) => {
  return {
    ...state.layout,
    currentUser: state.currentUser?.user || {},
  };
};

export default connect(mapStatetoProps)(VerticalLayout);
