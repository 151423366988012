import { createAction } from "redux-actions";

import {
  REQUEST_PLACEMENTS_SUCCESS,
  REQUEST_PLACEMENTS_FAILURE,
  REQUEST_TOURS_SUCCESS,
  REQUEST_TOURS_FAILURE,
  UPDATE_TOURS_SUCCESS,
  UPDATE_TOURS_FAILURE,
  LOADING_TOURS,
  REQUEST_PRINT_MODES_SUCCESS,
  REQUEST_PRINT_MODES_FAILURE,
  REQUEST_THEMES_SUCCESS,
  REQUEST_THEMES_FAILURE,
  ADD_THEMES_SUCCESS,
  ADD_THEMES_FAILURE,
  CREATE_ENTERPRISE_SUCCESS,
  CREATE_ENTERPRISE_FAILURE,
  EDIT_ENTERPRISE_SUCCESS,
  EDIT_ENTERPRISE_FAILURE,
  REQUEST_MAX_PRINT_SIZES_SUCCESS,
  REQUEST_MAX_PRINT_SIZES_FAILURE,
  GET_PRICING_TEMPLATES_SUCCESS,
  GET_PRICING_TEMPLATES_FAILURE,
  ADD_PRICING_TEMPLATES_SUCCESS,
  ADD_PRICING_TEMPLATES_FAILURE,
  EDIT_PRICING_TEMPLATES_SUCCESS,
  EDIT_PRICING_TEMPLATES_FAILURE,
  DELETE_PRICING_TEMPLATES_SUCCESS,
  DELETE_PRICING_TEMPLATES_FAILURE,
  GET_ORDER_STATUS_COLOR_TAGS_SUCCESS,
  GET_ORDER_STATUS_COLOR_TAGS_FAILURE,
  ADD_ORDER_STATUS_COLOR_TAGS_SUCCESS,
  ADD_ORDER_STATUS_COLOR_TAGS_FAILURE,
  SAVE_USER_SETTINGS_SUCCESS,
  SAVE_USER_SETTINGS_FAILURE,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_FAILURE,
  SAVE_SHIPPING_UPCHARGE_SUCCESS,
  SAVE_SHIPPING_UPCHARGE_FAILURE,
  GET_SHIPPING_UPCHARGE_SUCCESS,
  GET_SHIPPING_UPCHARGE_FAILURE,
} from "../constants/actionTypes";
import * as Settings from "../api/settings";

const requestPlacementsSuccess = createAction(REQUEST_PLACEMENTS_SUCCESS);
const requestPlacementsFailure = createAction(REQUEST_PLACEMENTS_FAILURE);

export function requestPlacements(query) {
  return (dispatch) =>
    Settings.requestPlacements(query)
      .then((data) => dispatch(requestPlacementsSuccess(data)))
      .catch((error) => dispatch(requestPlacementsFailure(error)));
}

const requestThemeSuccess = createAction(REQUEST_THEMES_SUCCESS);
const requestThemeFailure = createAction(REQUEST_THEMES_FAILURE);

export function requestTheme(query) {
  return (dispatch) =>
    Settings.requestTheme(query)
      .then((data) => dispatch(requestThemeSuccess(data)))
      .catch((error) => dispatch(requestThemeFailure(error)));
}

const addThemeSuccess = createAction(ADD_THEMES_SUCCESS);
const addThemeFailure = createAction(ADD_THEMES_FAILURE);

export function addTheme(params) {
  return (dispatch) =>
    Settings.addTheme(params)
      .then((data) => dispatch(addThemeSuccess(data)))
      .catch((error) => dispatch(addThemeFailure(error)));
}

const createThemeSuccess = createAction(CREATE_ENTERPRISE_SUCCESS);
const createThemeFailure = createAction(CREATE_ENTERPRISE_FAILURE);

export function createEnterprise(params) {
  return (dispatch) =>
    Settings.createEnterprise(params)
      .then((data) => dispatch(createThemeSuccess(data)))
      .catch((error) => dispatch(createThemeFailure(error)));
}

const editEnterpriseSuccess = createAction(EDIT_ENTERPRISE_SUCCESS);
const editEnterpriseFailure = createAction(EDIT_ENTERPRISE_FAILURE);

export function editEnterprise(params) {
  return (dispatch) =>
    Settings.editEnterprise(params)
      .then((data) => dispatch(editEnterpriseSuccess(data)))
      .catch((error) => dispatch(editEnterpriseFailure(error)));
}

const requestToursSuccess = createAction(REQUEST_TOURS_SUCCESS);
const requestToursFailure = createAction(REQUEST_TOURS_FAILURE);
const loadingTours = createAction(LOADING_TOURS);

export function requestTours(query) {
  return (dispatch) => {
    dispatch(loadingTours(true));
    return Settings.requestTours(query)
      .then((data) => dispatch(requestToursSuccess(data)))
      .catch((error) => dispatch(requestToursFailure(error)))
      .finally(() => dispatch(loadingTours(false)));
  };
}

const updateToursSuccess = createAction(UPDATE_TOURS_SUCCESS);
const updateToursFailure = createAction(UPDATE_TOURS_FAILURE);

export function updateTours(query) {
  return (dispatch) =>
    Settings.updateTours(query)
      .then((data) => dispatch(updateToursSuccess(data)))
      .catch((error) => dispatch(updateToursFailure(error)));
}

const requestPrintModesSuccess = createAction(REQUEST_PRINT_MODES_SUCCESS);
const requestPrintModesFailure = createAction(REQUEST_PRINT_MODES_FAILURE);

export function requestPrintModes(query) {
  return (dispatch) =>
    Settings.requestPrintModes(query)
      .then(({ data }) => dispatch(requestPrintModesSuccess(data)))
      .catch((error) => dispatch(requestPrintModesFailure(error)));
}

const requestMaxPrintSizesSuccess = createAction(
  REQUEST_MAX_PRINT_SIZES_SUCCESS
);
const requestMaxPrintSizesFailure = createAction(
  REQUEST_MAX_PRINT_SIZES_FAILURE
);

export function requestMaxPrintSizes() {
  return (dispatch) =>
    Settings.requestMaxPrintSizes()
      .then(({ data }) => dispatch(requestMaxPrintSizesSuccess(data)))
      .catch((error) => dispatch(requestMaxPrintSizesFailure(error)));
}

const getPricingTemplatesSuccess = createAction(GET_PRICING_TEMPLATES_SUCCESS);
const getPricingTemplatesFailure = createAction(GET_PRICING_TEMPLATES_FAILURE);

export function getPricingTemplates() {
  return (dispatch) =>
    Settings.getPricingTemplates()
      .then((data) => dispatch(getPricingTemplatesSuccess(data)))
      .catch((error) => dispatch(getPricingTemplatesFailure(error)));
}

const addPricingTemplatesSuccess = createAction(ADD_PRICING_TEMPLATES_SUCCESS);
const addPricingTemplatesFailure = createAction(ADD_PRICING_TEMPLATES_FAILURE);

export function addPricingTemplates(params) {
  return (dispatch) =>
    Settings.addPricingTemplates(params)
      .then((data) => dispatch(addPricingTemplatesSuccess(data)))
      .catch((error) => dispatch(addPricingTemplatesFailure(error)));
}

const editPricingTemplatesSuccess = createAction(
  EDIT_PRICING_TEMPLATES_SUCCESS
);
const editPricingTemplatesFailure = createAction(
  EDIT_PRICING_TEMPLATES_FAILURE
);

export function editPricingTemplates(id, data) {
  return (dispatch) =>
    Settings.editPricingTemplates(id, data)
      .then((data) => dispatch(editPricingTemplatesSuccess(id, data)))
      .catch((error) => dispatch(editPricingTemplatesFailure(error)));
}

const deletePricingTemplatesSuccess = createAction(
  DELETE_PRICING_TEMPLATES_SUCCESS
);
const deletePricingTemplatesFailure = createAction(
  DELETE_PRICING_TEMPLATES_FAILURE
);

export function deletePricingTemplates(id) {
  return (dispatch) =>
    Settings.deletePricingTemplates(id)
      .then((data) => dispatch(deletePricingTemplatesSuccess(id, data)))
      .catch((error) => dispatch(deletePricingTemplatesFailure(error)));
}

const getOrderStatusColorTagsSuccess = createAction(
  GET_ORDER_STATUS_COLOR_TAGS_SUCCESS
);
const getOrderStatusColorTagsFailure = createAction(
  GET_ORDER_STATUS_COLOR_TAGS_FAILURE
);

export function getOrderStatusColorTags(params) {
  return (dispatch) =>
    Settings.getOrderStatusColorTags(params)
      .then((data) => dispatch(getOrderStatusColorTagsSuccess(data)))
      .catch((error) => dispatch(getOrderStatusColorTagsFailure(error)));
}

const addOrderStatusColorTagsSuccess = createAction(
  ADD_ORDER_STATUS_COLOR_TAGS_SUCCESS
);
const addOrderStatusColorTagsFailure = createAction(
  ADD_ORDER_STATUS_COLOR_TAGS_FAILURE
);

export function addOrderStatusColorTags(params) {
  return (dispatch) =>
    Settings.addOrderStatusColorTags(params)
      .then((data) => dispatch(addOrderStatusColorTagsSuccess(data)))
      .catch((error) => dispatch(addOrderStatusColorTagsFailure(error)));
}

const getUserSettingsSuccess = createAction(GET_USER_SETTINGS_SUCCESS);
const getUserSettingsFailure = createAction(GET_USER_SETTINGS_FAILURE);

export function getUserSettings(params) {
  return (dispatch) =>
    Settings.getUserSettings(params)
      .then((data) => dispatch(getUserSettingsSuccess(data)))
      .catch((error) => dispatch(getUserSettingsFailure(error)));
}

const saveUserSettingsSuccess = createAction(SAVE_USER_SETTINGS_SUCCESS);
const saveUserSettingsFailure = createAction(SAVE_USER_SETTINGS_FAILURE);

export function saveUserSettings(params) {
  return (dispatch) =>
    Settings.saveUserSettings(params)
      .then((data) => dispatch(saveUserSettingsSuccess(data)))
      .catch((error) => dispatch(saveUserSettingsFailure(error)));
}

const saveShippingPriceUpchargeSuccess = createAction(
  SAVE_SHIPPING_UPCHARGE_SUCCESS
);
const saveShippingPriceUpchargeFailure = createAction(
  SAVE_SHIPPING_UPCHARGE_FAILURE
);

export function saveShippingPriceUpcharge(params) {
  console.log({ params });
  return (dispatch) =>
    Settings.saveShippingPriceUpcharge(params)
      .then((data) => dispatch(saveShippingPriceUpchargeSuccess(data)))
      .catch((error) => dispatch(saveShippingPriceUpchargeFailure(error)));
}

const getShippingPriceUpchargeSuccess = createAction(
  GET_SHIPPING_UPCHARGE_SUCCESS
);
const getShippingPriceUpchargeFailure = createAction(
  GET_SHIPPING_UPCHARGE_FAILURE
);

export function getShippingPriceUpcharge(params) {
  console.log({ params });
  return (dispatch) =>
    Settings.getShippingPriceUpcharge(params)
      .then((data) => dispatch(getShippingPriceUpchargeSuccess(data)))
      .catch((error) => dispatch(getShippingPriceUpchargeFailure(error)));
}
