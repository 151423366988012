import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import "firebase/functions";
import "firebase/storage";

// import { isTestEnv } from "../helpers/common";
const isTestEnv = false;

const API_KEY = isTestEnv
  ? "AIzaSyB46-j4jz_9SAW9jb26jvxn7XpW7lAS90E"
  : "AIzaSyBBelgl_vhZiTnUvxL5U_TAD9vopqQbC8I";
const PROJECT_NAME = isTestEnv
  ? "riverr-enterprise-test"
  : "riverr-enterprise-user";
const MESSAGING_SENDER_ID = isTestEnv ? "689886529820" : "1032772938785";
const APP_ID = isTestEnv
  ? "1:689886529820:web:2bc41f55b64f36bb9092d9"
  : "1:1032772938785:web:b3a609881b413fdac44760";
const MEASUREMENT_ID = isTestEnv ? "G-1VE5XH299X" : "G-CLLNW3M6EB";

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: `${PROJECT_NAME}.firebaseapp.com`,
  databaseURL: `https://${PROJECT_NAME}.firebaseio.com`,
  projectId: `${PROJECT_NAME}`,
  storageBucket: `${PROJECT_NAME}.appspot.com`,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};

// import { projectFirestore } from "../firebase";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            const userCurrent = firebase.auth().currentUser;
            resolve(userCurrent);
            // projectFirestore
            //   .collection("users")
            //   .doc(user.user.uid)
            //   .set({
            //     userId: firebase.auth().currentUser.uid,
            //     email: userCurrent.email,
            //     name: userCurrent.email,
            //   })
            //   .then((s) => console.log(s))
            //   .catch((err) => console.log(err));
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password, customToken = null) => {
    if (customToken) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signInWithCustomToken(customToken)
          .then(
            (user) => {
              resolve(user);
              localStorage.setItem("authUser", JSON.stringify(user.user));
            },
            (error) => {
              reject(this._handleError(error));
            }
          );
      });
    }
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(user);
            localStorage.setItem("authUser", JSON.stringify(user.user));
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
          localStorage.removeItem("authUser");
          localStorage.removeItem("riverrTheme");
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));

    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        localStorage.setItem("userDoc", JSON.stringify(doc.data()));
      });
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

initFirebaseBackend(firebaseConfig);
const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const projectFunctions = firebase.functions();
const obj = JSON.parse(localStorage.getItem("authUser"));

export {
  firebaseConfig,
  getFirebaseBackend,
  initFirebaseBackend,
  obj,
  projectFunctions,
  projectStorage,
  projectFirestore,
};
