import { uniq, keyBy } from "lodash";

import {
  UPDATE_GETTING_STARTED_STEPS,
  UPDATE_CURRENT_ACTIVE_STEP,
  REQUEST_ONBOARDING_DETAILS_SUCCESS,
  UPDATE_RETAIL_PRODUCT_STEPS,
  UPDATE_CUSTOMIZABLE_PRODUCT_STEPS,
  UPDATE_PRE_PRODUCTION_STEPS,
  UPDATE_PRODUCTION_STEPS,
  UPDATE_DASHBOARD_TASKS_SUCCESS,
  UPDATE_GETTING_STARTED_ENTERPRISE_ADMIN_STEPS,
} from "../../constants/actionTypes";
import {
  GETTING_STARTED_STEPS,
  USER_GETTING_STARTED_STEPS,
  NEW_RETAILS_PRODUCTS_STEPS,
  NEW_CUSTOMIZABLE_PRODUCT_STEPS,
  PRE_PRODUCTION_STEPS,
  PRODUCTION_STEPS,
  GETTING_STARTED_ENTERPRISE_ADMIN_STEPS,
} from "../../constants/dashboard";

const GETTING_STARTED_STEPS_KEYED = keyBy(
  Object.values(GETTING_STARTED_STEPS),
  "mappingKey"
);

const GETTING_STARTED_ENTERPRISE_ADMIN_STEPS_KEYED = keyBy(
  Object.values(GETTING_STARTED_ENTERPRISE_ADMIN_STEPS),
  "mappingKey"
);

const USER_GETTING_STARTED_STEPS_KEYED = keyBy(
  Object.values(USER_GETTING_STARTED_STEPS),
  "mappingKey"
);

const NEW_RETAILS_PRODUCTS_STEPS_KEYED = keyBy(
  Object.values(NEW_RETAILS_PRODUCTS_STEPS),
  "mappingKey"
);

const NEW_CUSTOMIZABLE_PRODUCT_STEPS_KEYED = keyBy(
  Object.values(NEW_CUSTOMIZABLE_PRODUCT_STEPS),
  "mappingKey"
);

const PRE_PRODUCTION_STEPS_KEYED = keyBy(
  Object.values(PRE_PRODUCTION_STEPS),
  "mappingKey"
);

const PRODUCTION_STEPS_KEYED = keyBy(
  Object.values(PRODUCTION_STEPS),
  "mappingKey"
);

const INITIAL_STATE = {
  gettingStartedStepsDone: [],
  gettingStartedEnterpriseAdminStepsDone: [],
  customizableProductStepsForUserDone: [],
  retailProductStepsDone: [],
  customizableProductStepsDone: [],
  preProductioStepsDone: [],
  productionStepsDone: [],
  currentActiveStep: null,
  onboardingDetails: {},
};

export default function dashboard(state = INITIAL_STATE, action) {
  let updatedSteps;

  switch (action.type) {
    case UPDATE_GETTING_STARTED_STEPS:
      updatedSteps = uniq([...state.gettingStartedStepsDone, action.payload]);
      return { ...state, gettingStartedStepsDone: updatedSteps };

    case UPDATE_GETTING_STARTED_ENTERPRISE_ADMIN_STEPS:
      updatedSteps = uniq([
        ...state.gettingStartedEnterpriseAdminStepsDone,
        action.payload,
      ]);
      return { ...state, gettingStartedEnterpriseAdminStepsDone: updatedSteps };

    case UPDATE_RETAIL_PRODUCT_STEPS:
      updatedSteps = uniq([...state.retailProductStepsDone, action.payload]);
      return { ...state, retailProductStepsDone: updatedSteps };

    case UPDATE_CUSTOMIZABLE_PRODUCT_STEPS:
      updatedSteps = uniq([
        ...state.customizableProductStepsDone,
        action.payload,
      ]);
      return { ...state, customizableProductStepsDone: updatedSteps };

    case UPDATE_PRE_PRODUCTION_STEPS:
      updatedSteps = uniq([...state.preProductioStepsDone, action.payload]);
      return { ...state, preProductioStepsDone: updatedSteps };

    case UPDATE_PRODUCTION_STEPS:
      updatedSteps = uniq([...state.productionStepsDone, action.payload]);
      return { ...state, productionStepsDone: updatedSteps };

    case UPDATE_CURRENT_ACTIVE_STEP:
      return { ...state, currentActiveStep: action.payload };

    case UPDATE_DASHBOARD_TASKS_SUCCESS:
    case REQUEST_ONBOARDING_DETAILS_SUCCESS:
      const onboardingDetails = action?.payload || {};
      const keysWithValueTrue = Object.entries(onboardingDetails || {}).filter(
        ([_key, value]) => value
      );
      const gssd = keysWithValueTrue
        .map(([key, _value]) => GETTING_STARTED_STEPS_KEYED?.[key]?.key)
        .filter(Boolean);
      const gseasd = keysWithValueTrue
        .map(
          ([key, _value]) =>
            GETTING_STARTED_ENTERPRISE_ADMIN_STEPS_KEYED?.[key]?.key
        )
        .filter(Boolean);
      const cpsfud = keysWithValueTrue
        .map(([key, _value]) => USER_GETTING_STARTED_STEPS_KEYED?.[key]?.key)
        .filter(Boolean);
      const rpsd = keysWithValueTrue
        .map(([key, _value]) => NEW_RETAILS_PRODUCTS_STEPS_KEYED?.[key]?.key)
        .filter(Boolean);
      const cpsd = keysWithValueTrue
        .map(
          ([key, _value]) => NEW_CUSTOMIZABLE_PRODUCT_STEPS_KEYED?.[key]?.key
        )
        .filter(Boolean);
      const ppsd = keysWithValueTrue
        .map(([key, _value]) => PRE_PRODUCTION_STEPS_KEYED?.[key]?.key)
        .filter(Boolean);
      const psd = keysWithValueTrue
        .map(([key, _value]) => PRODUCTION_STEPS_KEYED?.[key]?.key)
        .filter(Boolean);

      return {
        ...state,
        onboardingDetails,
        gettingStartedStepsDone: gssd,
        gettingStartedEnterpriseAdminStepsDone: gseasd,
        customizableProductStepsForUserDone: cpsfud,
        retailProductStepsDone: rpsd,
        customizableProductStepsDone: cpsd,
        preProductioStepsDone: ppsd,
        productionStepsDone: psd,
      };

    default:
      return state;
  }
}
