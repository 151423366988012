import axios from "axios";

import { getToken } from "../helpers/localStorageCache";
import { makeSearchParams } from "../helpers/string";
import MOCK_DATA from "../constants/mockUserData";
import { createHttpLink } from "@apollo/client";

/**
 * To debug any sub domain
 * set debugginMode to TRUE
 * change x-uid and host name to that of the reuqired tenant
 */

export const debuggingMode = false;
export const mockUserAndDomain = MOCK_DATA["enterprisePro"];
export const getUID = () =>
  debuggingMode ? mockUserAndDomain?.uid : `${getToken("authUser")?.uid}`;
export const getDomainHostname = () =>
  debuggingMode ? mockUserAndDomain?.domain : `${window.location.hostname}`;

let nodeServer = "https://riverr-enterprise-user.uc.r.appspot.com";
let pythonServer =
  "https://riverr-enterprise-integrations-dot-riverr-enterprise-user.uc.r.appspot.com";

export const httpLink = createHttpLink({
  uri:
    // "//localhost:8081/graphQL"
    "https://riverr-enterprise-graphql-dot-riverr-enterprise-user.uc.r.appspot.com/graphQL",
});

// nodeServer = "//localhost:4000";
// pythonServer = "//localhost:5000";

const clientUrl = (uri, server) => {
  let uriPath = uri;

  const pattern = /^https?:/;
  if (!pattern.test(uri)) {
    uriPath = uri.startsWith("/") ? uri : `/${uri}`;

    if (server === "python") {
      return `${pythonServer}${uriPath}`;
    }

    return `${nodeServer}${uriPath}`;
  }

  return uriPath;
};

const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;
  const objectKeys = Object.keys(obj);

  objectKeys.forEach((property) => {
    if (namespace) {
      formKey = `${namespace}[${property}]`;
    } else {
      formKey = property;
    }

    const value = obj[property];

    if (Array.isArray(value)) {
      // value is an Array
      arrayToFormData(fd, formKey, value); // eslint-disable-line no-use-before-define
    } else if (typeof value === "object" && !(value instanceof File)) {
      // value is a nested object
      objectToFormData(value, fd, formKey);
    } else {
      // value is a string or a File object
      fd.append(formKey, value);
    }
  });

  return fd;
};

const arrayToFormData = (fd, formKey, value) => {
  value.forEach((a) => {
    if (typeof a === "object" && !(a instanceof File)) {
      // elements of array is nested object
      objectToFormData(value, fd, `${formKey}[]`);
    } else {
      // elements of array is string or File object
      fd.append(`${formKey}[]`, a);
    }
  });
};

const buildParam = (params, asJSON = true) => {
  if (asJSON) {
    return JSON.stringify(params);
  }
  return objectToFormData(params);
};

const getDefaultOptions = () => ({
  method: "GET",
  headers: {
    Accept: "application/json",
    "x-uid": getUID(),
    "domain-hostname": getDomainHostname(),
  },
});

function ajax(uri, options = {}) {
  let pathString = uri;
  let defaultOptions = getDefaultOptions();

  defaultOptions = {
    ...defaultOptions,
    method: options.method || defaultOptions.method,
  };

  if (options.data) {
    if (options.formData) {
      defaultOptions.data = buildParam(options.data, false);
    } else {
      defaultOptions.headers["Content-Type"] =
        "application/json; charset=UTF-8";
      defaultOptions.data = buildParam(options.data);
    }
  }

  if (options.headers) {
    defaultOptions.headers = options.headers;
  }

  if (options.query) {
    pathString = `${pathString}?${makeSearchParams(options.query)}`;
  }

  defaultOptions.url = clientUrl(pathString, options.server);

  return axios(defaultOptions);
}

export default ajax;
